    import React from "react";

    const Education = [
    {
        sub: "Secondary",
        collage: "Himanchal Boarding School",
        duration: "May, 2008 - May, 2019",
    },
    {
        sub: "Higher Secondary",
        collage: "Sagarmatha Higher Secondary School",
        duration: "Aug, 2019 - Jul, 2021",
    },
    {
        sub: "BSc (Hons) Computing",
        collage: "Informatics College Pokhara (ICP)",
        duration: "Dec, 2021 - Dec, 2024",
    },
    ];

    const Experience = [
    {
        position: "Backend Developer(Laravel)",
        company: "Tuki Soft Pvt.Ltd.",
        duration: "Apr, 2023 - Sep, 2023",
    },
    {
        position: "Intern - MERN stack",
        company: "Clinch Tech",
        duration: "Sep, 2023 - Dec, 2023",
    },
    ];

    export default function Details() {
    return (
        <div className="bg-primary text-white py-10">
        <div className="flex justify-center">
            <div className="w-mobile md:w-desktop">
            {/* Main Grid for Layout */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Education Section */}
                <div
                className="bg-primary p-6 rounded-lg shadow-lg"
                style={{
                    boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.75)", // Corrected box-shadow syntax
                }}
                >
                <div className="flex flex-col justify-center items-start">
                    <h1 className="text-[1.8rem] md:text-[2.5rem] font-bold pb-5">
                    Education
                    <span className="block bg-white h-1 w-full mt-[1px]"></span>
                    </h1>
                </div>
                {/* Education List */}
                <div className="flex flex-col gap-4">
                    {Education.map((edu, index) => (
                    <div key={index} className="border-b-2 pb-4">
                        <div className="text-lg flex justify-between">
                        <h2>{edu.collage}</h2>
                        <span>{edu.duration}</span>
                        </div>
                        <div className="text-md mt-2">{edu.sub}</div>
                    </div>
                    ))}
                </div>
                </div>

                {/* Work Section */}
                <div
                className="bg-primary p-6 rounded-lg shadow-lg    "
                style={{
                    boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.75)", // Corrected box-shadow syntax
                }}
                >
                <div className="flex flex-col justify-center items-start">
                    <h1 className="text-[1.8rem] md:text-[2.5rem] font-bold pb-5">
                    Work
                    <span className="block bg-white h-1 w-full mt-[1px]"></span>
                    </h1>
                </div>
                {/* Experience List */}
                <div className="flex flex-col gap-4">
                    {Experience.map((exp, index) => (
                    <div key={index} className="border-b-2 pb-4">
                        <div className="text-lg flex justify-between">
                        <h2>{exp.position}</h2>
                        <span>{exp.duration}</span>
                        </div>
                        <div className="text-md mt-2">{exp.company}</div>
                    </div>
                    ))}
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
    }
