import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";

const Header = () => {
  const [bgColor, setBgColor] = useState("black");
  const [textColour ,setTextColour] = useState("black");
  const [hoverColour ,setHoverColour] = useState("white");
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = window.innerHeight * 0.7;
  
      if (scrollPosition > threshold) {
        setTextColour("white");
        setBgColor("white");
        setHoverColour("black");

      } else {
        setTextColour("black");
        setBgColor("black");
        setHoverColour("white");
      }
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <motion.div
      initial={{
        y: -200,
      }}
      animate={{
        y: 0,
      }}
      exit={{
        y: -200,
      }}
      transition={{
        duration: 1,
        ease: "easeInOut",
      }}
      className={`header z-[999999]  text-${textColour}   
      flex justify-center items-center  pt-3  sticky top-0
         bg-opacity-50  backdrop-filter backdrop-blur-sm`}
    >
      <AnimatePresence>
        <div
          
          className=" w-mobile md:w-desktop "
        >
          <header className="flex justify-between items-center p-2  z-50  ">
            <div className="">
              <h1 className="text-4xl font-extrabold  ">삶</h1>
            </div>
            <div>
              <ul className="flex justify-between items-center capitalize text-2xl font-extrabold gap-2 md:gap-4">
                <li className={`group border p-1 hover:bg-${textColour} rounded-md h-8 md:h-10  aspect-square flex flex-col justify-center items-center gap ease-out duration-200 transform hover:scale-125`}>
                  <div className={`relative h-full w-full bg-gradient-to-r rounded-lg flex justify-center items-center group-hover:bg-${textColour}`}>
                    <div className={`h-4 w-4 border-2 border-${textColour} group-hover:border-${hoverColour} hover:bg-${textColour} rounded-full`}></div>
                    <div className={`absolute top-0 right-0 h-1 w-1 bg-${textColour}  group-hover:bg-${hoverColour} rounded-full `}></div>
                  </div>
                </li>

                <li className={`group border hover:bg-${textColour} hover:text-${hoverColour}  rounded-md h-8 md:h-10 aspect-square flex justify-center items-center ease-out duration-200 transform hover:scale-125`}>
                  <FaFacebookF className={` group-hover:text-${hoverColour} text-[1.2rem] md:text-[1.5rem]  `} />
                </li>
                  
                <li className={`group border hover:bg-${textColour} hover:text-${hoverColour}  rounded-md h-8 md:h-10 aspect-square flex justify-center items-center ease-out duration-200 transform hover:scale-125`}>
                  <FaLinkedinIn className={` group-hover:text-${hoverColour} text-[1.2rem] md:text-[1.5rem] `} />
                </li>

                <li className={`group border hover:bg-${textColour} hover:text-${hoverColour}  rounded-md h-8 md:h-10 aspect-square flex justify-center items-center ease-out duration-200 transform hover:scale-125`}>
                  <FaXTwitter className={` group-hover:text-${hoverColour} text-[1.2rem] md:text-[1.5rem] `} />
                </li>
              </ul>
            </div>
            <div>
              <button className={`border-[1px]  px-3 py-1 rounded-md text-xl font-bold text-[0.8rem] md:text-[1.5rem]  hover:bg-${bgColor} hover:text-${hoverColour} ease-out duration-200 transform hover:scale-125`}>
                Resume
              </button>
            </div>
          </header>
        </div>
      </AnimatePresence>
    </motion.div>
  );
};
export default Header;
