import { motion, AnimatePresence } from "framer-motion";

function loadingScreen() {
  return (
    <div className="w-screen h-screen flex justify-center items-center font-black-and-white ">
      <AnimatePresence>
        <motion.div
         initial={{ opacity: 0, scale: 0 }}
         animate={{ opacity: 1, scale: 1 }}
         transition={{ duration: 0.5 }}
        //   initial={{
        //     scale: 0,
        //   }}
        //   animate={{
        //     scale: 1,
        //   }}
        //   exit={{
        //     scale: 0,
        //   }}
        //   transition={{ ease: "easeOut", duration: 0.5 }}
          className="aspect-square "
        >
          <div className="text-[40vw] leading-none">삶</div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default loadingScreen;
