import logo from "./logo.svg";
import Header from "./component/include/home/Header.jsx";
import Hero from "./component/home/Hero.jsx";
import Wave from "./component/home/Wave.jsx";
import About from "./component/home/About.jsx";
import TechStack from "./component/home/techStack.jsx";
import Projects from "./component/home/projects.jsx";
import BentoDemo from "./component/home/test.jsx";

import LoadingScreen from "./component/home/loadingScreen.jsx";
import MouseTracker from "./component/mouserTrack/tracker.jsx";
import LocomotiveScroll from "locomotive-scroll";
import Details from "./component/home/Details.jsx";
import Contact from "./component/home/Contact.jsx";
import Footer from "./component/include/home/Footer.jsx";
import Recipes from "./component/barista/Recipes.jsx";
function App() {
  const locomotiveScroll = new LocomotiveScroll();

  return (
    <div className="App  bg-white ">
      {/* <Recipes/> */}
      {/* <LoadingScreen/> */}
      <MouseTracker />
      <Header />
      <Hero />
      <Wave />
      <About />
      <TechStack />
      <Projects />
      <Details />
      <Contact />

      <Footer />

      {/* test */}
      {/* <BentoDemo/> */}
    </div>
  );
}

export default App;
