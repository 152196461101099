import React from "react";
import { FaRegCopyright } from "react-icons/fa";

export default function Footer() {
  return (
    <div className=" bg-primary border-t border-secondary">
      <div className=" flex justify-center items-center p-2 text-white gap-1">
        <div className=" flex justify-center items-center">
          <FaRegCopyright />
        </div>
        2024 Ayush Gurung. All rights reserved.
      </div>
    </div>
  );
}
