import React from "react";

function About() {
  return (
    <div className="w-full h-[60vh] y-20   bg-primary text-white z-[9999] flex justify-center items-center m-0 p-0 top-1">
      <div className="w-mobile md:w-desktop">
        <div className="w-full">
          <div className=" flex justify-center items-center">
            {/* <div className="grid grid-cols-7 gap-2"> */}
            <div className="md:w-4/5 col-span-4 flex justify-center p-4 flex-col items-center">
              <div className=" text-[1.8rem] md:text-[3rem] pb-5 flex gap-2">
                Hi, I'm{" "}
                <div className="text-secondary font-semibold">
                  Ayush
                  <span className="block bg-white h-1 w-full mt-[1px]"></span>
                </div>
              </div>
              <p className="text-[1rem] md:text-[1.8rem] text-center sm:text-[2rem]   ">
                Welcome! I'm a Full-Stack Developer skilled in crafting
                intuitive frontends and powerful backends. I build seamless,
                scalable solutions to bring your ideas to life. Let's create
                something amazing together!
              </p>
            </div>
            {/* <div className="col-span-3 flex justify-center p-4 items-center">
              <img
                src="./profile.png"
                alt=""
                className="h-full object-cover bg-transparent"
              />
            </div> */}
          </div>
          {/* <div>tech stack</div> */}
        </div>
      </div>
    </div>
  );
}

export default About;
