import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./hero.css";

// import required modules
import { Autoplay } from "swiper/modules";

const Hero = () => {

  
  return (
    <div
      data-scroll-section=""
      data-scroll=""
      data-scroll-speed="-0.5"
      className="w-full h-[60vh]  pt-1 z-[9998] pb-0 mb-0 "
    >
      <div className=" text-primary flex justify-center items-center  mt-20">
        <div className="banner w-mobile md:w-desktop cursor-default font-bold tracking-tighter">
          <div className="text-[3rem] md:text-[5.5rem] leading-tight">Hello,</div>
          <div className="text-[3rem] md:text-[5.5rem] leading-none ">
            I am
            <span className="text-[4rem] md:text-[7.5rem] leading-none px-5 ">Ayush Gurung</span>{" "}
          </div>
          <div className=" flex items-center gap-3 leading-snug">
            <Swiper
              spaceBetween={50}
              centeredSlides={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper left-0"
            >
              <SwiperSlide>
                <h1>Back</h1>
              </SwiperSlide>
              <SwiperSlide>
                <h1>Front</h1>
              </SwiperSlide>
            </Swiper>
            <div className=" flex justify-center items-center h-full">
              <div className="w-[3vw] h-[.7vw] top-1 bg-primary"></div>
            </div>
            <h1 className="leading-tight text-[1.8rem] md:text-[5.5rem]">End Developer</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
