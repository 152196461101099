import React, { useState, useEffect } from "react";

const data = [
  {
    name: "Figma",
    img: "./tech/Figma.png",
    colour: "#eb4c1d",
  },
  {
    name: "Tailwind",
    img: "./tech/tailwind.png",
    colour: "#2fa3f7",
  },

  {
    name: "JavaScript",
    img: "./tech/JavaScript.png",
    colour: "#f4e229",
  },
  {
    name: "React",
    img: "./tech/React.png",
    colour: "#04d1f6",
  },
  {
    name: "PHP",
    img: "./tech/PHP.png",
    colour: "#7b7fb6",
  },
  {
    name: "Laravel",
    img: "./tech/Laravel.png",
    colour: "#fe5443",
  },

  {
    name: "NodeJs",
    img: "./tech/NodeJs.png",
    colour: "#87cf34",
  },
  {
    name: "Express",
    img: "./tech/express.png",
    colour: "#87cf34",
  },

  {
    name: "Mongo",
    img: "./tech/mongodb.png",
    colour: "#fe5443",
  },
  {
    name: "MySql",
    img: "./tech/mysql.png",
    colour: "#fe5443",
  },

  {
    name: "Prisma ",
    img: "./tech/prisma.png",
    colour: "#2fa3f7",
  },
  {
    name: "Sequelize ",
    img: "./tech/sequelize.png",
    colour: "#2fa3f7",
  },
  {
    name: "Docker",
    img: "./tech/docker.png",
    colour: "#2fa3f7",
  },
  {
    name: "Photoshop",
    img: "./tech/Photoshop.png",
    colour: "#2fa3f7",
  },
  {
    name: "Illustrator",
    img: "./tech/Illustrator.png",
    colour: "#320000",
  },
];

export default function TechStack() {
  const [isPaused, setIsPaused] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null); // To track which card is hovered

  const [slideNo, setSlideNo] = useState(0);

  // useEffect(() => {
  //   // count no of slide
  //   setSlideNo(data.length);

  //   const slider = document.querySelector(".slider");
  //   if (isPaused) {
  //     slider.style.animationPlayState = "paused";
  //   } else {
  //     slider.style.animationPlayState = "running";
  //   }
  // }, [isPaused]);

  return (
    <div className="bg-primary overflow-hidden">
      <div className="flex justify-center bg-primary text-white w-screen py-10">
        <div className="w-mobile md:w-desktop">
          <div className="h-full w-full">
            <div className=" w-full flex items-center justify-center mb-10">
              <h1 className="font-semibold  text-[1.8rem] md:text-[3rem] pb-5 ">
                My Skills
                <span className="block bg-white h-1 w-full mt-[1px]"></span>
              </h1>
            </div>
            <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-6 xl:grid-cols-7 gap-4 ">
              {data.map((skill, index) => (
                <div
                  key={index}
                  className="p-2 rounded-xl flex flex-col items-center shadow-xl drop-shadow-xl duration-300"
                  style={{
                    boxShadow:
                      hoveredIndex === index
                        ? `0px 0px 8px 1px ${
                            skill.colour || "rgba(0,0,0,0.75)"
                          }`
                        : "0px 0px 45px 17px transparent",
                  }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <img
                    src={skill.img}
                    alt={skill.name}
                    className="logo w-16 h-16 object-contain mb-2 mix-blend-color-burn"
                  />

                  <p className="text-lg font-medium">{skill.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      {/* <div className="h-[30vh] w-full relative">
        <div className="slider w-max   h-[30vh] aspect-square px-5 flex flex-row gap-9 items-center justify-center">
         
          {[...data, ...data].map((item, index) => (
            <div
              key={index}
              className={` flex justify-center items-center  border border-white w-[15vh] h-[15vh] md:w-[10vw] md:h-[20vh] rounded-2xl ease-out duration-200 transform hover:scale-125 hover:border-[${item.colour}]`}
              onMouseEnter={() => {
                setIsPaused(true);
                setHoveredIndex(index);
              }}
              onMouseLeave={() => {
                setIsPaused(false);
                setHoveredIndex(null);
              }}
              style={{
                borderColor: hoveredIndex === index ? item.colour : "white", // Change border color when hovered
              }}
            >
              <div className="relative grayscale hover:grayscale-0 flex justify-center items-center">
                <img
                  src={item.img}
                  alt={item.name}
                  className="h-[10vh] md:h-[15vh]  object-cover bg-transparent rounded-2xl "
                />
                <p className="text-white text-center absolute bottom-0">
                  {item.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <style jsx>{`
        .slider {
          display: flex;
          animation: slide 15s linear infinite;
        }
        @keyframes slide {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
      `}</style> */}
    </div>
  );
}
