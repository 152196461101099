import React, { useEffect, useRef, useState } from "react";
import './Tracker.css'; // Create this CSS file separately

const Tracker = () => {
  const circlesRef = useRef([]);
  const coords = useRef({ x: 0, y: 0 });

  const [mouseColour, setMouseColour] = useState('black');


  useEffect(() => {
    const handleMouseMove = (e) => {
      coords.current = { x: e.clientX, y: e.clientY };
    };

    window.addEventListener("mousemove", handleMouseMove);

    const circles = circlesRef.current;

    circles.forEach((circle, index) => {
      circle.x = 0;
      circle.y = 0;
      
    });

    const animateCircles = () => {
      let { x, y } = coords.current;
      circles.forEach((circle, index) => {
        circle.style.left = `${x - 12}px`;
        circle.style.top = `${y - 12}px`;
        circle.style.transform = `scale(${(circles.length - index) / circles.length})`;

        circle.x = x;
        circle.y = y;

        const nextCircle = circles[index + 1] || circles[0];
        x += (nextCircle.x - x) * 0.3;
        y += (nextCircle.y - y) * 0.3;
      });

      requestAnimationFrame(animateCircles);
    };

    animateCircles();

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = window.innerHeight * 0.3;
  
      if (scrollPosition > threshold) {
        setMouseColour("white");

      } else {
        setMouseColour("black");
      }
    };
    window.addEventListener("scroll", handleScroll);



    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    
      {[...Array(100)].map((_, i) => (
        <div key={i} className={`circle bg-${mouseColour}`} ref={el => circlesRef.current[i] = el}></div>
      ))}
      
    </>
  );
};

export default Tracker;
